import { createHomework, createModuleApi, Homework } from 'momai'
import { Ref, ref } from 'vue'

const homeworkApi = <T>() => createModuleApi<T>('homework')

const detail = (homeworkId: number) => homeworkApi<Homework>()({
  subUrl: 'getHomeworkDetail',
  method: 'post',
  params: { homeworkId }
})

const useFetchDetail = (homeworkId: string | string[], fetchEnd = () => {}): {
  homework: Ref<Homework>;
} => {
  const homework = ref<Homework>(createHomework({}))

  detail(Number(homeworkId)).then(r => {
    homework.value = r
    fetchEnd()
  })

  return {
    homework
  }
}

const nextWorkName = (courseId: number) => homeworkApi<string>()({
  subUrl: `autoCreateName/${courseId}`
})

const save = (homework: Homework) => homeworkApi<string>()({
  subUrl: 'save',
  method: 'post',
  params: homework
})

const update = (params: any) => homeworkApi<string>()({
  subUrl: 'update',
  method: 'post',
  params
})

export {
  update,
  save,
  useFetchDetail,
  nextWorkName,
  detail
}
