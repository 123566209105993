
import { computed, defineAsyncComponent, ref } from 'vue'
import { Dialog, Notify, Toast } from 'vant'
import { useRoute } from 'vue-router'
import { getUserId } from '@/utils/auth'
import { goBack } from '@/states/layout'
import useUploadVideo from '@/utils/useUploadVideo'
import { createHomework, Homework } from 'momai'
import { nextWorkName, save } from '@/api/entity/homework'
import { useCanSubmitHomework } from '@/api/user/student'
import useFetchDetail from '@/views/course-series/course-detail/useFetchDetail'
import { detailCourseSeries } from '@/views/course-series/course-list/state'

export default {
  components: {
    Pay: defineAsyncComponent(() => import('@/views/course-series/Pay.vue'))
  },
  setup: () => {
    const route = useRoute()
    const { courseId } = route.params
    const showPay = ref(false)
    const videoUploader = ref<HTMLInputElement | null>(null)
    const homework = ref<Homework>(createHomework({
      courseId: Number(courseId),
      userId: Number(getUserId())
    }))

    useFetchDetail({ courseId })
    const accessEnd = ref(false)

    // 表单数据
    const uploadDirectory = `homework-user-${getUserId()}`
    const {
      duration,
      percentage,
      videoUrl,
      coverUrl,
      videoChange,
      openVideoPicker
    } = useUploadVideo({
      picker: videoUploader,
      uploadDirectory,
      uploadFinished: () => {
        const h = homework.value
        h.video = videoUrl.value
        h.videoCover = coverUrl.value
        h.videoLength = duration.value
      }
    })

    nextWorkName(Number(courseId)).then(r => {
      homework.value.workName = r
    })

    const canNotSubmit = computed(() => {
      const hw = homework.value
      return !hw.video && !hw.videoCover && !hw.img && !hw.text
    })
    const showProgress = ref(false)
    const { resetSumitStatus, userCantSubmitHomework } = useCanSubmitHomework(Number(courseId), () => {
      const laodingInstance = Toast.loading('')
      setTimeout(() => {
        showPay.value = !userCantSubmitHomework.value && detailCourseSeries.value.type === 0
        accessEnd.value = true
        laodingInstance.clear()
        showProgress.value = userCantSubmitHomework.value
      }, 500)
    })

    return {
      accessEnd,
      showProgress,
      userCantSubmitHomework,
      resetSumitStatus,
      homework,
      showPay,
      percentage,
      videoUploader,
      videoChange,
      canNotSubmit,
      openVideoPicker,
      detailCourseSeries,
      submit: () => {
        Dialog.confirm({
          title: '提示',
          message: '等待导师点评，一旦点评完成将会获得魔豆积分！'
        }).then(() => {
          save(homework.value).then(r => {
            if (r) {
              Notify({
                message: '作业提交成功！',
                type: 'success'
              })
              goBack()
            } else {
              Toast.fail('您未加入该班级！')
            }
          })
        }).catch(() => {})
      }
    }
  }
}
